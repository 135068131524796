import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import MessagesAPI from '../../api/messages.api';
import MessagesState from '../../types/MessagesState';
import { MessageChannelId } from '../../types/Messages/Message';

const initialState: MessagesState = {
    user: JSON.parse(localStorage.getItem('tgUser') || 'null'),
    search: '',
    page: 1,
    total: 0,
    searchOpened: false,
    messages: [],
    messagesLoading: 'idle',
    messagesLoadingError: null,
    messagesSearching: 'idle',
    messagesSearchingError: null,
    messagesHashtags: [],
    messagesHashtagsLoading: 'idle',
    messagesHashtagsLoadingError: null,
};

export const fetchMessages = createAsyncThunk('messages/fetch', async () => {
    return await MessagesAPI.fetch();
});

export const fetchHashtagsByChannels = createAsyncThunk(
    'messages/fetch-hashtags',
    async (channelsIds: MessageChannelId[]) => {
        return await MessagesAPI.fetchHashtagsByChannels(channelsIds);
    }
);

export const messagesSearch = createAsyncThunk(
    'messages/search',
    async (params: { search: string; userId: number | undefined; page: number }) => {
        return await MessagesAPI.search(params);
    }
);

const messagesSlice = createSlice({
    name: 'messages',
    initialState,
    reducers: {
        setSearch: (state, action) => {
            state.search = action.payload;
        },
        setSearchOpened: (state, action) => {
            state.searchOpened = action.payload;
        },
        setUser: (state, action) => {
            state.user = action.payload;
            localStorage.setItem('tgUser', JSON.stringify(action.payload));
        },
        setPage: (state, action) => {
            state.page = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchMessages.pending, (state) => {
                state.messagesLoading = 'loading';
            })
            .addCase(fetchMessages.fulfilled, (state, action: PayloadAction<any>) => {
                state.messages = action.payload;
                state.messagesLoading = 'succeeded';
            })
            .addCase(fetchMessages.rejected, (state, action: PayloadAction<any>) => {
                state.messagesLoadingError = action.payload;
                state.messagesLoading = 'failed';
            })
            .addCase(fetchHashtagsByChannels.pending, (state) => {
                state.messagesHashtagsLoading = 'loading';
            })
            .addCase(fetchHashtagsByChannels.fulfilled, (state, action: PayloadAction<any>) => {
                state.messagesHashtags = action.payload;
                state.messagesHashtagsLoading = 'succeeded';
            })
            .addCase(fetchHashtagsByChannels.rejected, (state, action: PayloadAction<any>) => {
                state.messagesHashtagsLoadingError = action.payload;
                state.messagesHashtagsLoading = 'failed';
            })
            .addCase(messagesSearch.pending, (state) => {
                state.messagesSearching = 'loading';
            })
            .addCase(
                messagesSearch.fulfilled,
                (
                    state,
                    action: PayloadAction<{
                        messages: any[];
                        total: number;
                    }>
                ) => {
                    state.messages = action.payload.messages;
                    state.total = action.payload.total;
                    state.messagesSearching = 'succeeded';
                }
            )
            .addCase(messagesSearch.rejected, (state, action: PayloadAction<any>) => {
                state.messagesSearchingError = action.payload;
                state.messagesSearching = 'failed';
            });
    },
});

const { actions, reducer } = messagesSlice;

export const { setSearch, setSearchOpened, setUser, setPage } = actions;

export default reducer;
