import React, { useEffect } from 'react';
import 'animate.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './css/index.scss';
import 'antd/dist/reset.css';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/animations/scale.css';
import Charts from './components/Charts';
import Header from './components/Header';
import Search from './components/Search';
import { Button, ConfigProvider } from 'antd';
import { themes } from './helpers/theme';
import digcomp from './assets/digcomp.png';
// @ts-ignore
import TelegramLoginButton, { TelegramUser } from 'telegram-login-button';
import 'react-horizontal-scrolling-menu/dist/styles.css';
import { messagesSearch, setSearchOpened, setUser } from './store/messages/messagesReducer';
import { useAppDispatch } from './store';
import { useSelector } from 'react-redux';
import AppState from './types/AppState';

function App() {
    const dispatch = useAppDispatch();

    const { user, search, page } = useSelector((state: AppState) => state.messages);

    const handleTelegramResponse = (user: TelegramUser) => {
        dispatch(setUser(user));
    };

    useEffect(() => {
        dispatch(
            messagesSearch({
                search,
                userId: user?.id,
                page,
            })
        );
        dispatch(setSearchOpened(true));
    }, [dispatch, search, page]);

    return (
        <ConfigProvider theme={themes['dark']}>
            <div className="app">
                <Header />
                <div className="page-content">
                    <div className="container">
                        <Search />
                        <a
                            className="digcomp-button"
                            href="https://digcomp.edfuture.ru"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <Button size="large">
                                <div className="digcomp-button__container">
                                    <img className="digcomp-button__icon" src={digcomp} alt="" />
                                    Индивидуальная диагностика цифровой грамотности
                                </div>
                            </Button>
                        </a>
                    </div>
                    <Charts />
                    {!user && (
                        <div className="telegram-button">
                            <TelegramLoginButton
                                botName="PTGStatsBot"
                                dataOnauth={(user: TelegramUser) => handleTelegramResponse(user)}
                                cornerRadius={20}
                            />
                        </div>
                    )}
                </div>
            </div>
        </ConfigProvider>
    );
}

export default App;
