import React, { useEffect } from 'react';
import ReactWordCloud, { Word } from 'react-wordcloud';
import { useSelector } from 'react-redux';
import chroma from 'chroma-js';
import AppState from '../types/AppState';
import { useAppDispatch } from '../store';
import {
    fetchHashtagsByChannels,
    setPage,
    setSearch,
    setSearchOpened,
} from '../store/messages/messagesReducer';

function Charts() {
    const dispatch = useAppDispatch();

    const { messagesHashtags, user } = useSelector((state: AppState) => state.messages);

    useEffect(() => {
        dispatch(fetchHashtagsByChannels([-1515994168, -1811036967]));
    }, [dispatch, user]);

    const options: any = {
        colors: chroma
            .scale(['#fafa6e', 'rgba(255,69,96,0.76)', '#5bd289', '#4c95bb'])
            .mode('lch')
            .colors(20),
        enableTooltip: true,
        deterministic: true,
        fontFamily: 'Inter',
        fontSizes: [8, 40],
        fontWeight: 600,
        padding: 5,
        rotations: 1,
        rotationAngles: [0, 90],
        transitionDuration: 1000,
    };

    const getWords = (hashtags: { hashtag: string; value: number }[] | undefined) => {
        return hashtags
            ? hashtags.map((hashtag) => ({
                  text: hashtag.hashtag,
                  value: hashtag.value,
              }))
            : [];
    };

    const handleWordClick = (word: Word) => {
        dispatch(setPage(1));
        dispatch(setSearch('#' + word.text));
        dispatch(setSearchOpened(true));
    };

    return (
        <div className="charts">
            <div className="charts-slider">
                <div className="charts-slider__slide">
                    <div className="charts-item">
                        <h2 className="charts-item__title">Телеграм канал «Дизайн Образования»</h2>
                        <div className="charts-item__content">
                            <ReactWordCloud
                                words={getWords(messagesHashtags[0]?.hashtags)}
                                options={options}
                                callbacks={{
                                    onWordClick: (word) => handleWordClick(word),
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Charts;
