import React, { useEffect, useRef } from 'react';
import { Input, Pagination, Skeleton, Tag, Typography } from 'antd';
import { setPage, setSearch, setSearchOpened } from '../store/messages/messagesReducer';
import { useAppDispatch } from '../store';
import { useSelector } from 'react-redux';
import AppState from '../types/AppState';
import chroma from 'chroma-js';

const SearchInput = Input.Search;
const { Paragraph } = Typography;

function Search() {
    const dispatch = useAppDispatch();

    const ref = useRef(null);

    const { messages, messagesSearching, search, searchOpened, page, total } = useSelector(
        (state: AppState) => state.messages
    );

    const handleSearch = (value: string) => {
        dispatch(setPage(1));
        dispatch(setSearch(value));
        dispatch(setSearchOpened(true));
    };

    const handleClickOutside = (event: { target: any }) => {
        // @ts-ignore
        if (ref.current && !ref.current.contains(event.target)) {
            dispatch(setSearchOpened(false));
        }
    };

    useEffect(() => {
        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    }, []);

    const colors = chroma
        .scale(['rgba(255,69,96,0.76)', '#5bd289', '#4c95bb'])
        .mode('lch')
        .colors(20);

    const results = messages.map((message) => {
        const hashtags = message.hashtags.map((hashtag) => (
            <Tag
                color={colors[Math.floor(Math.random() * (20 - 1 + 1) + 1)]}
                style={{
                    marginBottom: 10,
                }}
            >
                {hashtag.title}
            </Tag>
        ));

        return (
            <div className="search-item" key={message.id}>
                <a
                    className="search-item__title"
                    href={`https://t.me/c/${message.channelId * -1}/${message.messageId}`}
                    target="_blank"
                    rel="noreferrer"
                >
                    <Paragraph ellipsis={{ rows: 2 }}>{message.text}</Paragraph>
                </a>
                {hashtags}
            </div>
        );
    });

    return (
        <div className="search" ref={ref}>
            <label className="search__label">Поисковик по материалам канала</label>
            <SearchInput
                placeholder="Поиск"
                size="large"
                onSearch={(value) => handleSearch(value)}
                onFocus={(e) => {
                    if (!e.currentTarget.contains(e.relatedTarget)) {
                        setSearchOpened(true);
                    }
                }}
                onChange={(e) => handleSearch(e.target.value)}
                value={search}
                allowClear
            />
            {searchOpened && search && (
                <div
                    className="search-result animate__animated animate__fadeIn"
                    data-duration="500"
                >
                    {messagesSearching !== 'loading' ? (
                        <>
                            <div className="search-items">{results}</div>
                            {results.length > 0 && (
                                <Pagination
                                    className="search-pagination"
                                    current={page}
                                    total={total}
                                    onChange={(page) => dispatch(setPage(page))}
                                    showSizeChanger={false}
                                />
                            )}
                            {results.length === 0 && 'По вашему запросу ничего не найдено'}
                        </>
                    ) : (
                        <Skeleton />
                    )}
                </div>
            )}
        </div>
    );
}

export default Search;
