import { theme as antdTheme } from 'antd';
import { ThemeConfig } from 'antd/es/config-provider/context';

export const themes: {
    light: ThemeConfig;
    dark: ThemeConfig;
} = {
    light: {
        algorithm: antdTheme.defaultAlgorithm,
        token: {
            colorPrimary: '#196ed1',
            colorLink: '#58a6ff',
            colorBgElevated: '#fff',
            borderRadius: 12,
            borderRadiusLG: 12,
            borderRadiusSM: 12,
            borderRadiusXS: 12,
        },
    },
    dark: {
        algorithm: antdTheme.darkAlgorithm,
        token: {
            colorPrimary: '#196ed1',
            colorBgContainer: '#0d1117',
            colorBorderSecondary: 'rgba(240,246,252,0.1)',
            colorBorder: 'rgba(240,246,252,0.1)',
            colorLink: '#58a6ff',
            colorBgElevated: '#1f78f1',
            borderRadius: 12,
            borderRadiusLG: 12,
            borderRadiusSM: 12,
            borderRadiusXS: 12,
        },
    },
};
