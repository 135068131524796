import React, { Suspense } from 'react';
import { Provider } from 'react-redux';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import axios from 'axios';
import pjson from '../package.json';
import store from './store';
import App from './App';

axios.defaults.baseURL = process.env.REACT_APP_API;

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
    <React.StrictMode>
        <Provider store={store}>
            <Suspense fallback={<></>}>
                <App />
            </Suspense>
        </Provider>
    </React.StrictMode>
);

console.log(`%c TG Stats version: ${pjson.version} `, 'background-color: #ffdd2d; color: #333;');

reportWebVitals();
