import axios from 'axios';
import { MessageChannelId } from '../types/Messages/Message';

const fetch = async (): Promise<any[]> => {
    try {
        const response = await axios.get(`/messages`);
        return response.data;
    } catch (error) {
        throw new Error();
    }
};

const search = async (params: {
    search: string;
    userId: number | undefined;
    page: number;
}): Promise<{
    messages: any[];
    total: number;
}> => {
    try {
        const response = await axios.get(`/messages/search`, {
            params,
        });
        return response.data;
    } catch (error) {
        throw new Error();
    }
};

const fetchHashtagsByChannels = async (
    channelsIds: MessageChannelId[]
): Promise<
    {
        channelId: MessageChannelId;
        hashtags: {
            hashtag: string;
            value: number;
        }[];
    }[]
> => {
    try {
        const response = await axios.get(`/messages/hashtags`, {
            params: {
                channelsIds,
            },
        });
        return response.data;
    } catch (error) {
        throw new Error();
    }
};

const MessagesAPI = { fetch, fetchHashtagsByChannels, search };

export default MessagesAPI;
