import React from 'react';

function Header() {
    return (
        <div className="header">
            <div className="container">
                <div className="header__container">
                    <div className="header-logo">
                        <span className="header-logo__description">
                            Интерактивный каталог инновационного образования
                        </span>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Header;
