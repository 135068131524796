import { configureStore } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import messagesReducer from './messages/messagesReducer';

const store = configureStore({
    reducer: {
        messages: messagesReducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({ immutableCheck: true, serializableCheck: true, thunk: true }),
    devTools: process.env.NODE_ENV !== 'production',
});

export type RootState = ReturnType<typeof store.getState>;
export const useAppDispatch = () => useDispatch<typeof store.dispatch>();

export default store;
